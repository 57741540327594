<template>
	<div>
		<Header ref="Header" />
		<div class="container">
			<div class="title">{{showData.title}}</div>
			<div class="list">
				<div v-html="showData.content"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import Header from '@/components/header.vue';
	export default {
		components: {
			Header
		},
		data() {
			return {
				showData: {

				},
				categoryCode:0,
			}
		},
		methods: {
			
		},
		mounted() {
			this.$refs.Header.setIndex(-1);
			this.categoryCode=this.$route.query.categoryCode || 0;
			if(this.categoryCode<0){
				alert('传参有误');
				this.$router.go(-1);
			}else{
				this.$api.getSheetpage(this.categoryCode).then(res=>{
					console.log(res,'协议内容');
					this.showData=res.data[0];
				})
			}
		}
	};
</script>

<style scoped>
	.title{
		text-align: center;
		padding-top: 30px;
		margin: 0 auto;
		width: 1100px;
		font-size: 20px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #000000;
	}
	.list{
		margin: 30px auto 0 auto;
		width: 1100px;
		padding-bottom: 60px;
	}
</style>
